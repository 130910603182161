export const fetchUserData = async (token: string, onUnauthorized: () => void) => {
  const response = await fetch(`${process.env.NEXT_PUBLIC_BACKEND_URL}user`, {
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  });

  if (response.ok) return await response.json();

  if (response.status === 401) {
    const errorData = await response.json();
    if (errorData.msg === "Token is not valid") onUnauthorized();
    throw new Error("Unauthorized: Token is invalid");
  }

  throw new Error("Failed to fetch user data");
};
