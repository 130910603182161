"use client";
import { useState, useEffect } from "react";
import { useTheme } from "next-themes";
import Image from "next/image";

export function ThemeToggle() {
  const { resolvedTheme, setTheme } = useTheme();

  const [isMounted, setIsMounted] = useState(false);
  useEffect(() => setIsMounted(true), []);
  if (!isMounted) return null;

  return (
    <>
      {resolvedTheme === "light" ? (
        <Image
          alt="moon"
          width={20}
          height={20}
          src="/icons/moon.svg"
          suppressHydrationWarning
          onClick={() => setTheme("dark")}
          onError={(e) => ((e.currentTarget.onerror = null), (e.currentTarget.src = "https://example.com/default.png"))}
        />
      ) : (
        <Image
          alt="sun"
          width={20}
          height={20}
          src="/icons/sun.svg"
          suppressHydrationWarning
          onClick={() => setTheme("light")}
          onError={(e) => ((e.currentTarget.onerror = null), (e.currentTarget.src = "https://example.com/default.png"))}
        />
      )}
    </>
  );
}
