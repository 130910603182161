"use client";
import { useState, useEffect } from "react";
import { useTheme } from "next-themes";
import Image from "next/image";
import Link from "next/link";

import styles from "./headerLogo.module.css";

export function HeaderLogo() {
  const { resolvedTheme } = useTheme();

  const [isMounted, setIsMounted] = useState(false);
  useEffect(() => setIsMounted(true), []);
  if (!isMounted) return null;

  return (
    <Link href={"/"} className={`${styles.logo} ${resolvedTheme === "dark" ? styles.dark : styles.light}`}>
      <Image
        alt="logo"
        width={160}
        height={60}
        src="/icons/logo/logo.png"
        onError={(e) => {
          e.currentTarget.onerror = null; // Prevent infinite loop
          e.currentTarget.src = "https://example.com/default.png"; // Fallback image
        }}
      />
    </Link>
  );
}
