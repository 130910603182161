import { useQuery } from "@tanstack/react-query";
import { useTokenStore } from "@/app/_store/token";
import { fetchUserData } from "@/app/_helpers/functions";

const useUserData = (handleUnauthorized: () => void) => {
  const token = useTokenStore((state) => state.token);

  const { data, isLoading, isError } = useQuery({
    queryFn: () => fetchUserData(token, handleUnauthorized),
    queryKey: ["userData"],
    enabled: !!token,
  });

  return { data, isLoading, isError };
};

export default useUserData;
