import { create } from "zustand";

type TokenStore = {
  token: string;
  setToken: (token: string) => void;
};

const encryptToken = (token: string) => btoa(token);
const decryptToken = (encryptedToken: string) => {
  try {
    return atob(encryptedToken);
  } catch (error) {
    console.error("Failed to decode token:", error);
    return "";
  }
};

export const useTokenStore = create<TokenStore>((set) => ({
  token:
    typeof window !== "undefined" && localStorage.getItem("token")
      ? decryptToken(localStorage.getItem("token") || "")
      : "",

  setToken: (token: string) => {
    const encryptedToken = encryptToken(token);
    if (typeof window !== "undefined") localStorage.setItem("token", encryptedToken);
    set(() => ({
      token,
    }));
  },
}));
