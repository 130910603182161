"use client";
import { useEffect } from "react";
import { useHistoryStore } from "@/app/_store/history";
import { usePathname } from "next/navigation";

export default function HeaderHistoryAdd() {
  const pathname = usePathname();
  const setHistory = useHistoryStore((state) => state.setHistory);
  useEffect(() => setHistory(pathname), [pathname, setHistory]);
  return <></>;
}
